import { ADD_ANALYSIS_ANSWER } from './actions';

const initialState = {
  response: null, // Initialize response as null since it's an object
};

const analysisReducer = (state = initialState, action) => {

    switch (action.type) {
      case ADD_ANALYSIS_ANSWER:
        return {
          ...state,
          response: action.payload,
        };
      default:
        return state;
    }
  };
   
export default analysisReducer; 